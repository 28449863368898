<template>
  <el-main>
    <div class="blodTitle">点亮高德地图</div>
    <div class="bannerImgBox">
      <img :src="imgUrlHead + 'banner.png'" alt="" />
      <div class="onceOpen" v-if="!isBuy" @click="tuBuy">立即开通</div>
    </div>
    <div class="shopSecond">
      <div class="left">
        <div class="blodTitle">高德旺铺介绍</div>
        <p><b>定位：</b>高德向生活服务平台转型的入门级商业产品</p>
        <p><b>服务对象：</b>商家平台小B商户</p>
        <p><b>为小B商户解决什么问题：</b>地图卖货、周边推广</p>
      </div>
      <div class="right">
        <img :src="imgUrlHead + 'jieshao01.png'" alt="" />
        <img :src="imgUrlHead + 'jieshao02.png'" alt="" />
      </div>
    </div>
    <div class="blodTitle">商家免费入驻</div>
    <div class="freeIn">全国店主，可自主在高德地图通过固定入口、快捷入口进行入驻。登录高德地图，打开“我的-我的店铺”快捷入口：搜索“我是商家”</div>
    <img style="width: 100%" :src="imgUrlHead + 'ruzhu.png'" alt="" />
    <div class="onceOpen onceOpenCenter" v-if="!isBuy" @click="tuBuy">立即开通</div>
    <div class="blodTitle">五大核心优势</div>
    <div class="manyScene">
      <div v-for="(item, index) in fiveAdvantage" :key="index" class="manySceneItem">
        <img :src="imgUrlHead + item.img" alt="" />
        <div style="margin: 10px 0">{{ item.name }}</div>
        <div style="color: #666666">{{ item.desc }}</div>
      </div>
    </div>
    <div class="blodTitle">旺铺八大权益</div>
    <el-tabs v-model="activeEight" @tab-click="handleClick">
      <el-tab-pane label="发布团购" name="1"> </el-tab-pane>
      <el-tab-pane label="在线营销" name="2"></el-tab-pane>
      <el-tab-pane label="周边推广" name="3"></el-tab-pane>
      <el-tab-pane label="经营参谋" name="4"></el-tab-pane>
      <el-tab-pane label="店铺装修" name="5"></el-tab-pane>
      <el-tab-pane label="流量曝光" name="6"></el-tab-pane>
      <el-tab-pane label="平台认证" name="7"></el-tab-pane>
      <el-tab-pane label="极速审核" name="8"></el-tab-pane>
    </el-tabs>
    <div class="eightBox">
      <div class="top">{{ activeItem.desc }}</div>
      <div class="bot">
        <div class="botImg" v-for="(item, index) in activeItem.images" :key="index">
          <img :src="imgUrlHead + item.src" alt="" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div v-if="!isBuy" @click="tuBuy" class="onceOpen onceOpenCenter">立即开通</div>
    <BuyDialog :buyBox="buyBox" @closeBuy="closeBuy" type="gaode"></BuyDialog>
  </el-main>
</template>

<script>
import { imgUrlHead } from '@/util/config'
import BuyDialog from './components/buyDialog.vue'
export default {
  components: {
    BuyDialog
  },
  data() {
    return {
      buyBox: false,
      imgUrlHead: imgUrlHead + 'gaode/',
      fiveAdvantage: [
        { name: '免费入驻，快速上地图', desc: '5分钟操作，24小时审核1次入驻，终身所有', img: 'icon_1.png' },
        { name: '高优展示，高德V认证', desc: '优先展示，增加曝光加V认证，高德权威商家', img: 'icon_2.png' },
        { name: '随时管理店铺信息', desc: '随时修改及时生效他人不可篡改', img: 'icon_3.png' },
        { name: '自主装修店铺主图', desc: '自主设置门脸照，增加曝光度商家提交图片，优选入相册', img: 'icon_4.png' },
        { name: '免费发布店铺营销', desc: '打折/优惠任您发挥0佣金发布，快速引客', img: 'icon_5.png' }
      ],
      activeEight: '1',
      eightBox: [
        {
          desc: '您可以在地图上，以【团购】或【代金券】形式，发布您店铺的优惠产品，宣传您的产品。让高德地图上大量高频重复使用的用户更及时看到您的优惠产品，直接在地图购买，增加您的销售收入',
          images: [
            { src: 'pic11.png', name: '发布团购和代金券' },
            { src: 'pic12.png', name: '店铺详情页展现效果' },
            { src: 'pic13.png', name: '频道页、列表页团购信息' },
            { src: 'pic14.png', name: '首页团购信息' }
          ]
        },
        {
          desc: '在地图上发布最新的小店公告、店铺动态，发布您有温度的小店品牌故事，也可以使用高德提供的免费模版发布您的个性原创海报，免费营销您的店铺。',
          images: [
            { src: 'pic21.png', name: '发布店铺公告' },
            { src: 'pic22.png', name: '店铺公告展现效果' },
            { src: 'pic23.png', name: '本地营销会场' },
            { src: 'pic24.png', name: '小店故事前端展示' }
          ]
        },
        {
          desc: '平台会组织线上营销和大促会场，把您的优惠产品推荐给更多周边的顾客。同时平台也会提供周边自助推广工具，方便您平时自助推广优惠产品，降低推广成本，增加店铺销量',
          images: [
            { src: 'pic31.png', name: '频道页、列表页优惠' },
            { src: 'pic32.png', name: '周边优惠' },
            { src: 'pic33.png', name: '店铺周边' }
          ]
        },
        {
          desc: '地图数据具有很强的地理位置特色，您可以在地图上看到您的店铺流量信息，顾客流量信息，及行业和同行的排名信息，店铺经营状况一目了解，经营数据一手掌控。',
          images: [
            { src: 'pic41.png', name: '功能入口' },
            { src: 'pic42.png', name: '经营参谋大数据' },
            { src: 'pic43.png', name: '查看店铺搜索、导航指数' },
            { src: 'pic44.png', name: '查看周边同类店铺排名' }
          ]
        },
        {
          desc: '提供多种多样的店铺装饰和美化工具，方便您自由上传及美化店铺头图，上传多种类的店铺相册，好看的店铺人见人受，好的品牌形象更有效吸引到店和消费。',
          images: [
            { src: 'pic51.png', name: '我的店铺' },
            { src: 'pic52.png', name: '资质信息填写' },
            { src: 'pic53.png', name: '免费海报制作' }
          ]
        },
        {
          desc: '购买旺铺即可享受搜索排名加权服务，在营业信息完善情况下(头图、评论数)，提升搜索排名，助力客流增长。',
          images: [
            { src: 'pic61.png', name: '店铺评价' },
            { src: 'pic62.png', name: '店铺热门推荐' },
            { src: 'pic63.png', name: '店铺排行榜' },
            { src: 'pic63.png', name: '小店故事前端展示' }
          ]
        },
        {
          desc: '平台加V认证，提升品牌高度和知名度，增加用户到店信赖度，让更多用户搜到您的店、精准到达您的店。更有信息自主维护、防篡改。',
          images: [
            { src: 'pic71.png', name: '高德商家认证' },
            { src: 'pic72.png', name: '商家认证显示' },
            { src: 'pic73.png', name: '路线规划' }
          ]
        },
        {
          desc: '极速审核:购买旺铺即可享受优先审核服务，资质齐全情况下平均2小时审核上线。',
          images: [
            { src: 'pic81.png', name: '名称、地址、位置、营业时间' },
            { src: 'pic82.png', name: '电话、门脸照' },
            { src: 'pic83.png', name: '门店信息填写' }
          ]
        }
      ],
      activeItem: {},
      isBuy: 1
    }
  },
  created() {
    this.activeItem = this.eightBox[this.activeEight - 1]
    this.getIsBuy()
  },
  methods: {
    getIsBuy() {
      this.$axios
        .post(this.$api.set.buyGorH.getIsPay, {
          type: 1
        })
        .then((res) => {
          if (res.code === 0) {
            this.isBuy = res.result.is_pay
          }
        })
    },
    handleClick() {
      this.activeItem = this.eightBox[this.activeEight - 1]
    },
    tuBuy() {
      this.buyBox = true
    },
    closeBuy() {
      this.buyBox = false
      this.getIsBuy()
    }
  }
}
</script>

<style lang="less" scoped>
.onceOpen {
  color: #fff;
  font-size: 20px;
  border-radius: 4px;
  background: #0074ff;
  box-shadow: 0px 8px 16px 0px rgba(0, 106, 234, 0.21);
  cursor: pointer;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 150px;
}
.el-main {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background-color: #fff;
  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;
    .subTitle {
      color: #5654f3;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
  }
  .bannerImgBox {
    position: relative;
    .onceOpen {
      position: absolute;
      right: 75px;
      top: 50%;
      transform: translateY(-50%);
    }
    img {
      width: 100%;
    }
  }
  .onceOpenCenter {
    margin: 50px auto;
    text-align: center;
  }
  .freeIn {
    white-space: nowrap;
    margin-bottom: 20px;
  }
  .shopSecond {
    display: flex;
    flex-wrap: wrap;
    .left {
      min-width: 352px;
      margin-right: 120px;
      p {
        margin-bottom: 20px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .manyScene {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 60px;
    .manySceneItem {
      flex: 0 0 200px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .eightBox {
    margin-bottom: 40px;
    .bot {
      display: flex;
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
